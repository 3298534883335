import { render, staticRenderFns } from "./EditField2.vue?vue&type=template&id=2a0acae1&scoped=true&"
import script from "./EditField2.vue?vue&type=script&lang=js&"
export * from "./EditField2.vue?vue&type=script&lang=js&"
import style0 from "./EditField2.vue?vue&type=style&index=0&id=2a0acae1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../WeHealth_2.0_Frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a0acae1",
  null
  
)

export default component.exports